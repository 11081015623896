'use strict';

exports.init = function (options) {
	var loadingIndicatorSettings = {
		IsLabelShown: false,
		CssClass: 'fullscreen',
		BackgroundColor: 'transparent'
	};

	$('.pt_storefront .section .btn').off('click.LoadingIndicator').on('click.LoadingIndicator', function() {
		$('body').loading(loadingIndicatorSettings);
	});
};