'use strict';

var _ = require('lodash');
var quantityStepper = require('../../../../app_storefront_core/cartridge/js/quantityStepper');
var account = require('./account');
var bonusProductsView = require('../bonus-products-view');
var quickview = require('../quickview');
var cartStoreInventory = require('../storeinventory/cart');
var cartEcho = require('../../../../app_storefront_core/cartridge/js/pages/cart_echo');
var ajax = require('../ajax');
var util = require('../util');
var formPrepare = require('./checkout/formPrepare');
var page = require('../page');
var TPromise = require('promise');
var tooltip = require('../tooltip');
var CheckoutConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var ShopPay = require('../../../../int_shoppay_sfra/cartridge/client/default/js/cart/initShopPayCart');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var RequestHelper = require('../../../../app_storefront_core/cartridge/js/helpers/RequestHelper');
var RewardsCredit = require('../../../../app_storefront_core/cartridge/js/pages/checkout/RewardsCredit');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var isCartPage = false;
var dataBuffer = {};
var options;

var $rewardsForm;
var $rewardsPaymentContainer;
var $rewardsAvailable;
var $rewardsApplied;
var loadingIndicatorSettings = {
	IsLabelShown: false,
	CssClass: 'fullscreen',
	BackgroundColor: 'transparent'
};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	if (page.ns && page.ns == 'cart') {
		$(window).on("load resize",function (e) {
			if ($(window).width() > SiteConstants.Responsive.Widths.Mobile) {
				if($('.error-form').length) {
					var $containerWidth = $('.pt_cart .confirmation-checkout .confirmation-message').innerWidth();
					$('.error-form').css({'width' : $containerWidth + 'px'});
					
					setTimeout(function(){
						var $containerHeight = $('.section-order-items .section-head').outerHeight(true),
							$errormessageHeight = $('.error-form').innerHeight();
								
							$('.pt_cart .section-checkout .form-checkout:first-child').css({'margin-top': 113 + 'px'});
					}, 1500);
				} else {
					setTimeout(function(){
						$('.pt_cart .section-checkout .form-checkout:first-child').css({'margin-top': 0 + 'px'});
					}, 1500);
				}
			}
		});
		
		$.ajax({
			dataType: 'json',
			type : "GET",
			url: Urls.checkLoggedinOPC,
			success: function (response) {
				if (typeof response != 'object') {
					try {
						response = JSON.parse(response);
						
						if ('Customer' in response && response.Customer.authenticated == true && 'isCartEmpty' in response && !response.isCartEmpty && !$('.section-checkout').hasClass('empty-cart')) {
							location.href = Urls.onePage;
						}
					} catch(e) {
						console.log(e);
					}
				}				
			},
			error: function(jqXHR, textStatus, errorThrown) {
				console.log(textStatus, errorThrown);
			}
		});
	}
	
	$(document).off('click', '#cart-table .item-edit-details a').on('click', '#cart-table .item-edit-details a', function (e) {
        e.preventDefault();
        
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    
    $(document).off('click', '#cart-table .bonus-item-actions a, #cart-table .item-details .bonusproducts a').on('click', '#cart-table .bonus-item-actions a, #cart-table .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        
        bonusProductsView.show(this.href);
    });

    $(document).off('keydown', 'form input[name$="_couponCode"]').on('keydown', 'form input[name$="_couponCode"]', function (e) {   	
    	if (e.which === SiteConstants.KeyCodes.Enter) {
    		e.preventDefault();
    		
    		$('.error.coupon-error').remove();
    		if ($(document.activeElement).val().length === 0 || e.which === SiteConstants.KeyCodes.Enter && $(this).val().length === 0) {
				$('<span/>', {"class":"error coupon-error", text : Resources.COUPON_ADD_ERROR}).insertAfter('.cart-coupon-code .pcode-coupon');
				
				return false;
			} else {
				 $('button[name$="addCoupon"]').trigger('click');
			}
    	}   	 
    });
	
    $(document).off('click', 'button[name$="deleteProduct"]').on('click', 'button[name$="deleteProduct"]', function (e) {
    	e.preventDefault();
    	
    	updateCart($(this).attr('name'), 'qty-change');
    });
 
    $(document).off('click', 'button[name$="addCoupon"]').on('click', 'button[name$="addCoupon"]', function (e) {
	   	e.preventDefault();
	   	
	   	$('.error.coupon-error').text('');
	   	var $form = $('.cart-coupon-code input');
		var name = $(this).attr('name');
		
		(function() {
			return TPromise.resolve($.ajax({
	            type: 'POST',
	            url: util.ajaxUrl(Urls.onePageSubmit),
	            data: $form.serialize() + '&' + name + '=' + name + '&respType=json'
	        }))
		}()).then(function (response) {
			if (response.success && response.success !== CheckoutConstants.ResponseCodes.AlreadyInBasket) {				
				 updateCart('dwfrm_cart_updateCart', 'qty-change');
			} else {
				var message = response.success == CheckoutConstants.ResponseCodes.AlreadyInBasket ? Resources.COUPON_ADD_ERROR_DUP : Resources.COUPON_ADD_ERROR;
				$('<span/>', {'class': 'error coupon-error', 'text': message}).insertAfter('.cart-coupon-code .pcode-coupon');
			}
	    }.bind(this));
	});
    
    $(document).off('click', 'button[name$="deleteCoupon"]').on('click', 'button[name$="deleteCoupon"]', function (e) {
    	e.preventDefault();
    	
		var name = $(this).attr('name');
		(function(){
			return TPromise.resolve($.ajax({
	            type: 'POST',
	            url: util.ajaxUrl(Urls.onePageSubmit),
	            data: $('form#cart-items-form').serialize() + '&' + name + '=' + name + '&respType=json'
	        }))
		}()).then(function (response) {
			updateCart('dwfrm_cart_updateCart', 'qty-change');
	    }.bind(this));
    });
    
    $(document).on('focus', 'input[name^="dwfrm_guestcheckout_username_"]', function () {
		$(this).removeClass('error');
		$('#dwfrm_guestcheckout span.error').remove();
    });
    
    $(document).off('keydown', 'form input[name^="dwfrm_guestcheckout_username_"]').on('keydown', 'input[name^="dwfrm_guestcheckout_username_"]', function (e) {   	
    	if (e.which === SiteConstants.KeyCodes.Enter) {		
    		e.preventDefault();
    		$('#dwfrm_guestcheckout span.error').remove();
    		
    		var $form = $('form.guest-checkout');
    		$form.validate().form();
    		
    		var email = $(document.activeElement).val();
    		var isEmailValid = validateEmail(email);
    		
    		if ($form.valid() && isEmailValid && $('#dwfrm_guestcheckout span.error').length < 1 ) {
    			renderGuestCheckout(email)
    		} else {
    			if ($('input[name^="dwfrm_guestcheckout_username_"].error').length == 0) {
    				invalidateGuestCheckout();
    			}
    		}
    	}   	 
    });
    
    $(document).off('click', 'button#guestCheckout').on('click', 'button#guestCheckout', function (e) {
    	e.preventDefault();
		$('#dwfrm_guestcheckout span.error').remove();
		
		var $form = $('form.guest-checkout');
		$form.validate().form();
		
		var email = $('input[name^="dwfrm_guestcheckout_username_"]').val();
		if ($('input[name^="dwfrm_guestcheckout_username_"].valid').length > 0) {
			var email = $('input[name^="dwfrm_guestcheckout_username_"].valid').val();
		}
		
		var isEmailValid = validateEmail(email);
    	
		if ($form.valid() && isEmailValid && $('#dwfrm_guestcheckout span.error').length < 1 ) {			
			renderGuestCheckout(email);
		} else {
			if ($('input[name^="dwfrm_guestcheckout_username_"].error').length == 0) {
				invalidateGuestCheckout();
			}
		}
    });
    
    $(document).off('click', '.order-total-payments [payment-method] .remove').on('click', '.order-total-payments [payment-method] .remove', function (e) {
		var paymentInstrumentUUID = $(e.currentTarget).closest('[payment-method').attr('uuid');
	
		if (paymentInstrumentUUID) {
			var requestPayload = {
				UUID: paymentInstrumentUUID
			}
		
			$.ajax({
				url: Urls.removePaymentInstrument,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify(requestPayload),
				dataType: 'json',
				success: function(data) {
					updateCart('dwfrm_cart_updateCart');
				}
			});
		}
	});

    $(document).off('Cart.Update').on('Cart.Update', function (e) {
    	updateCart('dwfrm_cart_updateCart');
    });
}

function validateEmail(email) {
	var isEmailValid = true;
	
	if (email.length == 0){
		isEmailValid = true;
	}
	
	var regexEmail = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
	if (email.length > 0 && email.match(regexEmail) == null) {
		isEmailValid = false;
		invalidateGuestCheckout();
	}
	
	return isEmailValid;
}

function invalidateGuestCheckout() {
	$('input[name^="dwfrm_guestcheckout_username_"]').removeClass('valid').addClass('error');
	$('.guest-checkout .field-wrapper').append('<span class="error custom-guestcheckout-error" style="display:block !important;">' + Resources.VALIDATE_EMAIL + '</span>');
}

function renderGuestCheckout(email) {
	
	if(email.length > 0){
		var optIn = false;
		
		if(SitePreferences.CORDIAL_ENABLED){
			var signupEvent = '';
			
			var auth_data = {
				email: email
			}
			var contact_data = {
				'channels': {
					'email': {
						'address': email,
						'subscribeStatus': 'none'
					}
				},				    
				'workflow_checkout_abandon': true
			};
			
			var guestProperties = {
				'email': email,
				'workflow_checkout_abandon': true
			};
			
			if ($('#guest_checkout_optin').prop("checked")) {
				var auth_data = {
					email: email
				}
				var contact_data = {
					'channels': {
						'email': {
							'address': email,
							'subscribeStatus': 'subscribed'				            
						}
					},				    
					'signup_source': 'checkout',
					'welcome_signup': true,
					'workflow_checkout_abandon': true
				};
				
				var properties = {
					'signup_source': 'checkout',
					'welcome_signup': true,
					'workflow_checkout_abandon': true
				};
				
				var signupEvent = ['event', 'email_signup', properties];
			}
			
			RequestHelper.Validate('Cordial-EmailSignup').then(function() {
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['contact', auth_data, contact_data],
						['event', 'guest_checkout', guestProperties],
						signupEvent
					]);
				}
			}).catch();
		}
	} else {
		var optIn = $('#guest_checkout_optin').prop("checked");
	}
	
	$.ajax({
		url: Urls.setEmailOnCart,
		type: "get",
		data: {email: email},
		success: function (response) {
			var data = JSON.parse(response);
				
			if (data.status == 'success') {
				window.location.href = Urls.onePage + ((window.innerWidth < SiteConstants.Responsive.Widths.Mobile) ? '#addressinfo' : '');
			}
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(textStatus, errorThrown);
		}
	});
	
	$.ajax({
		url: Urls.setEmailOptin,
		type: "get",
		data: {optIn: optIn},
		success: function (response) {
			var data = JSON.parse(response);
				
			if (data.status == 'success') {
				window.location.href = Urls.onePage + ((window.innerWidth < SiteConstants.Responsive.Widths.Mobile) ? '#addressinfo' : '');
			}
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(textStatus, errorThrown);
		}
	});
}

var updateCartBuffer;
var updateCartXmlHttpRequestBuffer;
function updateCart(triggerElementName, updateCartOptions, isCartPage) { 
	if (updateCartXmlHttpRequestBuffer) {
		for (var updateCartXmlHttpRequestKey in updateCartXmlHttpRequestBuffer) {
			var updateCartXmlHttpRequest = updateCartXmlHttpRequestBuffer[updateCartXmlHttpRequestKey];
			
			if (updateCartXmlHttpRequest.readyState !== 4) { 
				updateCartXmlHttpRequest.abort();
			}
		}
	}
	updateCartXmlHttpRequestBuffer = [];
	updateCartBuffer = [];

	getCartHtml(triggerElementName, updateCartOptions, isCartPage);
	if (!isCartPage) {
		getOrderSummaryCalloutHtml(triggerElementName, updateCartOptions, isCartPage);
	}
}

function getCartHtml(triggerElementName, updateCartOptions, isCartPage) {
    var form = $(".update-cart-button").parents('form');
    updateCartBuffer['Cart'] = null;

	if (form.attr('action')) {
		if (isCartPage == undefined) {
			isCartPage = $('.pt_cart').length > 0;
		}

		updateCartXmlHttpRequestBuffer['getCartHtml'] = $.ajax({
	        url : form.attr('action'),
		    type : "POST",
	        data : triggerElementName + '=' + triggerElementName + '&selectedPaymentMethod=' + $('input[name*="_selectedPaymentMethodID"]').val() + '&format=ajax',
		    success : function (data) {
		    	updateCartBuffer['Cart'] = data;
				getCartData(triggerElementName, updateCartOptions, isCartPage);
				updateCartRender(triggerElementName, updateCartOptions, isCartPage);
		    }
		});
	}
}

function getOrderSummaryCalloutHtml(triggerElementName, updateCartOptions, isCartPage) {
    updateCartBuffer['OrderSummaryCallout'] = null;

	updateCartXmlHttpRequestBuffer['getOrderSummaryCalloutHtml'] = $.ajax({
		url: Urls.getOrderSummaryCalloutContentSlot,
		dataType: 'html',
		success: function (data) {
			updateCartBuffer['OrderSummaryCallout'] = data;
			updateCartRender(triggerElementName, updateCartOptions, isCartPage);
		}
	});
}

function getCartData(triggerElementName, updateCartOptions, isCartPage) {
	updateCartBuffer['CartData'] = null;

	updateCartXmlHttpRequestBuffer['getCartData'] = $.ajax({
	    url : Urls.getCartData,
		type : "POST",
		data: {
			isCart: isCartPage
		},
	    success : function (data) {
	    	$('[has-local-delivery-shipment]').attr('has-local-delivery-shipment', data.HasLocalDeliveryShipment || false);
	    	$('[has-physical-shipment]').attr('has-physical-shipment', data.HasPhysicalShipment || false);
			$('[has-warehouse-shipment]').attr('has-warehouse-shipment', data.HasWarehouseShipment || false);
			$('[has-warehouse-shipment]').attr('has-shipping-methods', data.ShippingMethods ? data.ShippingMethods.join(' ') : false);
	    	$('[has-gift-cards]').attr('has-gift-cards', data.HasGiftCards || false);
	    	$('[has-gift-cards-only]').attr('has-gift-cards-only', data.HasGiftCardsOnly || false);
			$('[has-store-pickup-shipment]').attr('has-store-pickup-shipment', (data.Bopis && data.Bopis.HasStorePickupShipment) || false);
			$('[has-store-pickup-shipment-only]').attr('has-store-pickup-shipment-only', (data.Bopis && data.Bopis.HasStorePickupShipmentOnly) || false);
			$('[has-in-store-pickup-shipment]').attr('has-in-store-pickup-shipment', (data.Bopis && data.Bopis.HasInStorePickupShipment) || false);
	    	$(document).trigger('CartData.Update', data);

	    	updateCartBuffer['CartData'] = data;
	    	if (data.Bopis) {
	    		updateCartBuffer['Bopis'] = data.Bopis;
	    	}
	    	
	    	if (!data.IsGlobaleValidOrInactive) {
	    		$('.section-checkout').addClass('global-e-validation-error');
	    		$('.section-checkout').find('.cart-errors').addClass('active');
	    	} else {
	    		$('.section-checkout').removeClass('global-e-validation-error');
	    		$('.section-checkout').find('.cart-errors').removeClass('active');
	    	}

			if ((!data.OrderTotal || !data.OrderTotal.balanceAvailable) || (data.ShippingRestrictions && data.ShippingRestrictions.Status == 'shippingRestrictionsError')) {
				if (!isCartPage) {
					$('.payment-methods-section').hide();
				}
			} else {
				if (isCartPage) {
					$('.guest-checkout .error-form').remove();
				} else {
					$('.payment-methods-section').show();
				}
			}
	    	
	    	handleOrderTotal(triggerElementName, updateCartOptions, isCartPage);
	    }
	});
}

function handleOrderTotal(triggerElementName, updateCartOptions, isCartPage) {
	updateCartBuffer['PaymentInstrumentHtml'] = null;
	var removeAction = '<a class="remove">Remove</a>';
	
	if (updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length > 0) {
		updateCartBuffer['PaymentInstrumentHtml'] = '';
	
		for (var paymentInstrumentKey in updateCartBuffer['CartData'].OrderTotal.paymentInstruments) {
			var paymentInstrument = updateCartBuffer['CartData'].OrderTotal.paymentInstruments[paymentInstrumentKey];
			var paymentInstrumentUUIDAttribute = 'uuid="' + paymentInstrument.UUID + '"';
			
			switch (paymentInstrument.paymentMethod.Id) {
				case PaymentMethodConstants.RewardsCredit:
		    		updateCartBuffer['PaymentInstrumentHtml'] += '<div ' + paymentInstrumentUUIDAttribute + ' payment-method="' + paymentInstrument.paymentMethod.Id + '" allow-remove="' + paymentInstrument.allowRemove + '">' +
		    			'<span class="label"><span class="name">B Rewarded</span>&nbsp;<span class="number">Reward</span></span>' +
		    			'<span class="value">' + paymentInstrument.amountDisplay + '</span>' +
		    		'</div>';
		    	break;
			
				default:
		    		updateCartBuffer['PaymentInstrumentHtml'] += '<div ' + paymentInstrumentUUIDAttribute + ' payment-method="' + paymentInstrument.paymentMethod.Id + '" allow-remove="' + paymentInstrument.allowRemove + '">' +
		    			'<span class="label"><span class="name">' + paymentInstrument.paymentMethod.Name + '</span>&nbsp;<span class="number">' + paymentInstrument.numberLastFour + '</span>' + removeAction + '</span>' +
		    			'<span class="value">' + paymentInstrument.amountDisplay + '</span>' +
		    		'</div>';
		    	break;
		    }
		}
	} else {
		$(document).trigger('GiftCard.Clear');
		$(document).trigger('RewardsCredit.Clear');
	}
	
	if (updateCartBuffer['CartData'].OrderTotal.balance < updateCartBuffer['CartData'].OrderTotal.total) {
		updateCartBuffer['BalanceDueHtml'] = '<span class="label">Balance Due</span>' +
			'<span class="value">' + updateCartBuffer['CartData'].OrderTotal.balanceDisplay + '</span>';
	}
	
	updateCartRender(triggerElementName, updateCartOptions, isCartPage);
}

function updateCartRender(triggerElementName, updateCartOptions, isCartPage) {
	if (updateCartBuffer['Cart'] && (isCartPage || (updateCartBuffer['OrderSummaryCallout'] && updateCartBuffer['CartData']))) {
		var $basketContainerHtml = $(updateCartBuffer['Cart']);
	
		if (updateCartBuffer['CartData'] && updateCartBuffer['CartData'].OrderTotal) {
			$basketContainerHtml.find('.order-total-price .value').html(updateCartBuffer['CartData'].OrderTotal.totalDisplay);
	    	$('.payment-section .order-total-price .value').html(updateCartBuffer['CartData'].OrderTotal.totalDisplay);
		
			if (updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length > 0) {
				$basketContainerHtml.find('.order-totals-table').attr('payments-applied', updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length);
	    		$('.payment-section').attr('payments-applied', updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length);
	    	} else {
	    		$basketContainerHtml.find('.order-totals-table').removeAttr('payments-applied');
	    		$('.payment-section').removeAttr('payments-applied');
	    	}
		
			$basketContainerHtml.find('.order-totals-table').attr('balance-due', updateCartBuffer['CartData'].OrderTotal.balance);
	    	$('.payment-section').attr('balance-due', updateCartBuffer['CartData'].OrderTotal.balance);
		
			if (updateCartBuffer['CartData'].OrderTotal.balance > 0) {
				$('.payment-method-options').show();
				$('.payment-section-footer[data-method="PayPal"] button').hide();
			} else {
				$('.payment-method-options').hide();
				$('.payment-section-footer[data-method="PayPal"] button').show();
			}
		
	    	if (updateCartBuffer['BalanceDueHtml']) {	
	    		$basketContainerHtml.find('.order-balance-due').html(updateCartBuffer['BalanceDueHtml']);
	    		$('.payment-section .order-balance-due').html(updateCartBuffer['BalanceDueHtml']);    		
	    	} else {
	    		$('.payment-section .order-balance-due').html('');
	    	}
	    	
	    	if (updateCartBuffer['PaymentInstrumentHtml']) {
	    		$basketContainerHtml.find('.order-total-payments').html(updateCartBuffer['PaymentInstrumentHtml']);
	    		$('.payment-section .order-total-payments').html(updateCartBuffer['PaymentInstrumentHtml']);
	    	} else {
	    		$('.payment-section .order-total-payments').html('');
	    	}
		}

		if (options && options.StoreUIVersion == '2024Redesign') {
			$('#main > .shell').html($basketContainerHtml);
		} else {
			$('.basket-container').html($basketContainerHtml);
		}
		
		if (updateCartBuffer['CartData']) {
			var orderTotalForAffirm = updateCartBuffer['CartData'].OrderTotal.balance;
			if (!orderTotalForAffirm && updateCartBuffer['CartData'].OrderTotal.adjustedMerchandizeTotalPrice) {
				orderTotalForAffirm = updateCartBuffer['CartData'].OrderTotal.adjustedMerchandizeTotalPrice;
			}
			
			if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui && orderTotalForAffirm >= updateCartBuffer['CartData'].AffirmMinimumOrderTotal) {
				$('.affirm-product-modal').attr('data-amount', orderTotalForAffirm * 100);
				affirm.ui.refresh();
			} else {
				$('.affirm-product-modal').attr('data-amount', 5000);
			}
		}

		if ($('.basket-container').find('.product-availability-list .not-available').length > 0) {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').attr('disabled', 'disabled');
			$('#errorBoxOPC').remove();
			$('#submitOPCPayment').after('<div id="errorBoxOPC" class="p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>' + Resources.Validation.Messages.Products.Availability + ':</b> ' + Resources.Validation.Messages.Products.AvailabilityExceeded + '</div>');
		} else if (updateCartBuffer['Bopis'] && updateCartBuffer['Bopis'].NumberOfBopisStoresInCart > updateCartBuffer['Bopis'].MaximumNumberOfBopisStoresInCart) {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').attr('disabled', 'disabled');
			$('#errorBoxOPC').remove();
			$('#submitOPCPayment').after('<div id="errorBoxOPC" class="p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>' + Resources.Validation.Messages.General.CartLimit + ':</b> ' + Resources.Validation.Messages.Bopis.MaximumStoresExceeded + '</div>');
		} else {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').removeAttr('disabled');
			$('#errorBoxOPC').remove();
		}
		
		if (updateCartBuffer['OrderSummaryCallout']) {	
			$('.callout-ordersummary').html(updateCartBuffer['OrderSummaryCallout']);
		}
		
	    if (SitePreferences.STORE_PICKUP) {
	        cartStoreInventory.init();
	    }
	    
	    account.initCartLogin();
	    checkCheckoutAccessibility();
	    	    
		if (options && options.StoreUIVersion == '2024Redesign') {
			$.ajax({url : Urls.cartItemCount, method: 'POST', dataType: 'json', success : function(data) {
				if (data && data.ProductCount != undefined) {
					$('.btn-minicart span').html(Math.round(data.ProductCount));
				}
			}});
		} else {
			if ($('.top-banner-bar-inner .btn-minicart').length) {
				$.ajax({url : Urls.cartMiniCart, data : {desktop: '1'}, dataType: 'html', success : function(data) {
					$('.mini-cart.banner-cart-holder.desktop').html(data);
				}});
				
				$.ajax({url : Urls.cartMiniCart, dataType: 'html', success : function(data) {
					$('.mini-cart.banner-cart-holder.mobile').html(data);
				}});
			}

			$.ajax({url: Urls.cartItemQuantity, data: {desktop: '1'}, dataType: 'html', success: function (data) {
				$('.confirmation-message .item-count').html(data);
				
				var quantity = parseInt($('.confirmation-message .item-count').find('span').text(), 10);
				if (!quantity) {
					$('.checkout-buttons.mobile-only').hide();
					if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
						$('.mini-cart-link .minicart-quantity').hide();
					}
				}
				
				if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
					if ($('.mini-cart-link .minicart-quantity').length) {
						$('.mini-cart-link .minicart-quantity').text(quantity);
					}
				}
			}});
		}
	    
	    if (triggerElementName.indexOf('deleteProduct') > -1) {
	    	$(document).trigger('refresh-required');
	    }

		if (!options || !options.EventTriggers || options.EventTriggers['update-summary']) {
			$(document).trigger('update-summary');
		}
		
		if (!options || !options.EventTriggers || options.EventTriggers['update-shipping']) {
			$(document).trigger('update-shipping');
		}
	    
	    $('[minimum-cart-total], [maximum-cart-total]').each(function(index, element) {
	    	var $this = $(this);
	    	var paymentMethodCartTotalMinimum = parseFloat($this.attr('minimum-cart-total'));
	    	var paymentMethodCartTotalMaximum = parseFloat($this.attr('maximum-cart-total'));
	    
	    	var isCartTotalMinimumValid = false;
	    	if (isNaN(paymentMethodCartTotalMinimum) || updateCartBuffer['CartData'].OrderTotal.balance >= paymentMethodCartTotalMinimum) {
	    		isCartTotalMinimumValid = true;
	    	}
	    	
	    	var isCartTotalMaximumValid = false;
	    	if (isNaN(paymentMethodCartTotalMaximum) || updateCartBuffer['CartData'].OrderTotal.balance <= paymentMethodCartTotalMaximum) {
	    		isCartTotalMaximumValid = true;
	    	}
	    	
	    	if (isCartTotalMinimumValid && isCartTotalMaximumValid) {
	    		$this.closest('.payment-method-option').attr('valid-for-balance-due', 'true');
	    	} else {
	    		$this.closest('.payment-method-option').attr('valid-for-balance-due', 'false');
	    	}
	    });
		
	    if (updateCartBuffer['CartData'] && !updateCartBuffer['CartData'].HasPhysicalShipment && $('.single.shipping-address-type-selector input:checked').val() != SiteConstants.AddressTypes.Customer) {
	    	$('.single.shipping-address-type-selector input[value="' + SiteConstants.AddressTypes.Customer + '"]').click();
	    }
		
		tooltip.init({
			items: '.shipping-method-tooltip',
			tooltipClass: 'shipping-method-tooltip'
		}, $('[shipping-method]'));

		tooltip.init({
			items: '.tooltip'
		});

		quantityStepper.init(updateCart);
		triggerSrFocus();
		$(document).trigger('QuantityStepper.TargetFocus');
	    $(document).trigger('Cart.UpdateSuccess');
		if ($('body').attr('loading')) {
    		$('body').loading('destroy');
    	}
	}
}

function triggerSrFocus() {
	$('[role="alert"]:visible').last().focus().addClass('focus-visible-indicator');
}

function checkCheckoutAccessibility() {
	if ($('.order-item').length == 0) {
		$('.section-checkout > div').removeClass('active');
		$('.billing-address-form, .shipping-address-cont, .shipping-payment-cont').hide();
		$('.form-checkout.login-element, .section-checkout').addClass('empty-cart');
	}
}

/**
 * @description Make the AJAX request to get customer data
 * @param {Element} form The form element that contains the item quantity and ID data
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var updateCustomerDetails = function (form, name) {
    var $form = $(form);

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.onePageLogin),
        data: $form.serialize() + '&' + name + '=' + name
    }));
};

/**
 * @description Make the AJAX request to reset customer password
 * @param {String} registered customer email
 * @returns {Promise}
 */
var resetCustomerPassword = function (email) {
	return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.resetOPCPass),
        data: 'email=' + email
    }));
};

/**
 * @description Make the AJAX request to reset customer password
 * @param {String} error message
 * @param {Element} element to add error after
 * @returns {Void}
 */
var showError = function(message, selector){
	var $errorContainer = selector.closest('field-wrapper').next('.error');

	if(!$errorContainer.prop('defaultText') || $errorContainer.text() != message){
		selector.prop('defaultText', $errorContainer.text());
	}
	
	$errorContainer.text(message).show();
};

/**
 * @description Make the AJAX request to reset customer password
 * @param {String} notification text
 * @param {Element} selector to apply message
 * @param {String} notification type: error or success
 * @returns {Void}
 */
function showNotification(text, $selector, messageType) {
	if ($selector.length == 0) {
		var $selector = $('<span/>',{'class': 'login-notification'}).wrap('<div class="formfield clearfix error-box"><div class="form-row required"><div class="field-wrapper"></div></div></div>');

		$selector.parent().parent().parent().insertBefore('form.opc-login .form-row-button');
	}

	$selector.removeClass('success error').addClass(messageType).text(text);
};

/**
 * @description Make the AJAX request to reset customer password
 * @param {Element} selector to clear notification type: error or success
 * @returns {Void}
 */
function clearNotification(selector){
	if(selector.length){
		$('.formfield.error-box').remove();
		selector.removeClass('success error');
	}
};

function searchRewards() {
	if (dataBuffer['RewardsAccountSearchRequest'] && dataBuffer['RewardsAccountSearchRequest'].readyState !== 4) {
		dataBuffer['RewardsAccountSearchRequest'].abort();
		dataBuffer['RewardsAccountSearchRequest'] = null;
	}
	$rewardsAvailable.html('');

	var payload = {
		EmailAddress: $rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val(),
		PhoneNumber: $rewardsForm.find('#dwfrm_billing_rewards_rewardsPhoneNumber').val(),
		ZipCode: $rewardsForm.find('#dwfrm_billing_rewards_rewardsZipCode').val(),
	}

	if ($rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val()) {
		payload.RewardsNumber = $rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val();
	}

	$('.btn.search-echo').loading({
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	});

	dataBuffer['RewardsAccountSearchRequest'] = $.ajax({
		type: 'POST',
		url: Urls.searchForRewards,
		contentType: 'application/json',
		dataType: 'json',
		data: JSON.stringify(payload),
		success: function(data) {
			renderRewardsData(data);
		},
		complete: function(xhr) {
			if ($('.btn.search-echo').attr('loading')) {
				$('.btn.search-echo').loading('destroy');
			}
		}
	});
}

function renderRewardsData(data) {
	if (data.Match && data.Rewards) {
		if (data.Rewards.rewardsNumber) {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val(data.Rewards.rewardsNumber);
		} else {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val('');
		}
		if (data.Rewards.email) {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val(data.Rewards.email);
		} else {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val('');
		}
		if (data.Rewards.phone) {
			$rewardsForm.find('.field.phonenumber').val(data.Rewards.phone);
		} else {
			$rewardsForm.find('.field.phonenumber').val('');
		}
		if (data.Rewards.zipValue) {
			$rewardsForm.find('.field.zipcode').val(data.Rewards.zipValue);
		} else {
			$rewardsForm.find('.field.zipcode').val('');
		}

		if (data.Match.RewardsEmail) {
			$rewardsForm.find('.rewards-email-container').attr('match', 'true');
		} else {
			$rewardsForm.find('.rewards-email-container').attr('match', 'false');
		}
		if (data.Match.RewardsPhone) {
			$rewardsForm.find('.input-container.phone').attr('match', 'true');
		} else {
			$rewardsForm.find('.input-container.phone').attr('match', 'false');
		}
		if (data.Match.RewardsZip) {
			$rewardsForm.find('.input-container.zip').attr('match', 'true');
		} else {
			$rewardsForm.find('.input-container.zip').attr('match', 'false');
		}

		var rewardsRowTemplate = _.template($('#template-rewards-credit-row-echo').html(), TemplateConstants.Lodash.Settings);
		var status = '';
		var message = '';
		if (parseFloat(data.Rewards.creditAmount) <= 0) {
			status = 'message';
			message = 'no rewards available';
		} else if ($rewardsForm.find('[is-applied]').attr('is-applied') == 'true') {
			status = 'applied';
		} else {
			status = 'pending';
		}

		var additionalData = '<table class="rewards-information">' +
								'<tr><td>Address</td><td>' + data.Rewards.address + '</td></tr>' +
								'<tr><td>City</td><td>' + data.Rewards.city + '</td></tr>' +
								'<tr><td>State</td><td>' + data.Rewards.state + '</td></tr>' +
								'<tr><td>Zip</td><td>' + data.Rewards.zipValue + '</td></tr>' +
								'<tr><td>Country</td><td>' + data.Rewards.country + '</td></tr>' +
								'<tr><td>Phone</td><td>' + data.Rewards.phone + '</td></tr>' +
							'</table><table class="rewards-points">' +
								'<tr><td>Points</td><td>' + data.Rewards.pointBalance + '</td></tr>' +
							'</table>';

		$rewardsApplied.html(rewardsRowTemplate({
			Status: status,
			RewardsNumber: data.Rewards.rewardsNumber,
			Name: data.Rewards.fullName,
			Email: data.Rewards.email,
			RewardsAvailable: data.Rewards.creditAmountFormatted.replace('.00', ''),
			RewardsExpirationDate: data.Rewards.creditExpirationDate || 'n/a',
			Message: message,
			AdditionalData: additionalData
		}));

		$rewardsPaymentContainer.attr('has-rewardsnumber', 'true');
		$rewardsAvailable.attr('rewards-found', 'true');
		if (data.Rewards.creditAmount > 0) {
			$rewardsAvailable.html('B Rewarded Member</br><span class="credit-available">' + data.Rewards.creditAmountFormatted.toFixed(0) + ' Available</span>');
		} else {
			$rewardsAvailable.html('B Rewarded Member</br><span class="points-available">' + new Number(data.Rewards.pointBalance ? data.Rewards.pointBalance : 0).toLocaleString() + ' Points</span>');
		}
	} else {
		$rewardsPaymentContainer.attr('has-rewardsnumber', 'false');
		$rewardsAvailable.removeAttr('rewards-found');
		$rewardsAvailable.html('');
	}
}

function initializeRedesignEvents() {
	var $cartHeader = $('.cart-header');
	var $rewardsContainer = $cartHeader.find('.cart-header-rewards');
	$rewardsForm = $rewardsContainer.find('.rewards');
	$rewardsPaymentContainer = $rewardsForm.find('[payment-method="REWARDS_CREDIT"]');
	$rewardsApplied = $rewardsForm.find('.rewards-applied');
	$rewardsAvailable = $rewardsContainer.find('.rewards-data');

	$rewardsContainer.off('click.UpdateRewards').on('click.UpdateRewards', '.update', function (event) {
		$('.cart-header').attr('status', 'rewards');
	});
	$rewardsForm.off('click.RewardsAccountSearch').on('click.RewardsAccountSearch', '.btn.search-echo', function(event) {
		event.preventDefault();
		searchRewards();
	});
	$rewardsForm.off('keyup.RewardsReset change.RewardsReset').on('keyup.RewardsReset change.RewardsReset', 'input', function(event) {
		var isRewardsFormPopulated = false;
		$rewardsForm.find('input').each(function() {
			if ($(this).val()) {
				isRewardsFormPopulated = true;
			}
		});
		if (isRewardsFormPopulated) {
			$rewardsForm.find('.btn.reset-echo').removeAttr('disabled');
		} else {
			$rewardsForm.find('.btn.reset-echo').attr('disabled', 'true');
		}
	});
	$rewardsForm.off('click.RewardsReset').on('click.RewardsReset', '.btn.reset-echo', function(event) {
		event.preventDefault();

		if ($rewardsApplied.find('.reward-row[status="applied"]').length > 0) {
			$rewardsApplied.find('.reward-row[status="applied"] .btn.remove').trigger('RewardsCredit.Remove', { ResetSessionData: true });
		} else {
			$.ajax({
				url: Urls.resetRewardsSessionData,
				type: 'POST'
			});
		}
		$rewardsApplied.html('');
		$rewardsForm.find('input').val('');
		$rewardsForm.find('.btn.reset-echo').attr('disabled', 'true');
		$rewardsForm.find('[match]').removeAttr('match');
		$rewardsAvailable.removeAttr('rewards-found');
	});
	$rewardsForm.off('click.CancelRewards').on('click.CancelRewards', '.cancel', function (event) {
		event.preventDefault();
		$('.cart-header').attr('status', 'default');
	});

	$(document).off('click.UpdateBillingAddress').on('click.UpdateBillingAddress', '.cart-header-billing-address .update', function (event) {
		$('.cart-header').attr('status', 'addressform');
	});
	$(document).off('click.SaveBillingAddress').on('click.SaveBillingAddress', '.cart-header-billing-address .save', function (event) {
		$('body').loading(loadingIndicatorSettings);
		var $addressFields = $(this).closest('form');
		//if (shippingAddressFormValidator.checkForm()) {
			setOPCAddress(Urls.setOpcAddress, $addressFields, 'dwfrm_billing_selectOpcAddress').then(function (data) {
				$('.cart-header').attr('status', 'data');
				$('.cart-header-billing-address').attr('status', 'data');
				$('.cart-header-billing-address-data .billing-email').text($('#dwfrm_billingaddress_email').val());
				$('.cart-header-billing-address-data .billing-phone').text($('#dwfrm_billingaddress_phone').val());
				$('.cart-header-billing-address-data .billing-postalcode').text($('#dwfrm_billingaddress_postal').val());
				$('.cart-header-rewards-data h4').attr('name', $('#dwfrm_billingaddress_firstName').val());
				var rewardsFieldsFilled = 0;
				if (!$('#dwfrm_billing_rewards_rewardsEmail').val()) {
					$('#dwfrm_billing_rewards_rewardsEmail').val($('#dwfrm_billingaddress_email').val());
					rewardsFieldsFilled++;
				}
				if (!$('#dwfrm_billing_rewards_rewardsPhoneNumber').val()) {
					$('#dwfrm_billing_rewards_rewardsPhoneNumber').val($('#dwfrm_billingaddress_phone').val());
					rewardsFieldsFilled++;
				}
				if (!$('#dwfrm_billing_rewards_rewardsZipCode').val()) {
					$('#dwfrm_billing_rewards_rewardsZipCode').val($('#dwfrm_billingaddress_postal').val());
					rewardsFieldsFilled++;
				}
				if (rewardsFieldsFilled > 1) {
					searchRewards();
				}
				$('body').loading('destroy');
				$(document).trigger('Cart.Update');
			});
		//}
	});
	$(document).off('click.CancelBillingAddress').on('click.CancelBillingAddress', '.cart-header-billing-address .cancel', function (event) {
		$('.cart-header').attr('status', 'default');
	});
	
	$(document).off('click.UpdateShippingAddress').on('click.UpdateShippingAddress', '.shipment-header .shipment-shipping-address .update', function (event) {
		$(this).closest('.shipment-header').attr('status', 'addressform');
	});

	$(document).off('click.SaveShippingAddress').on('click.SaveShippingAddress', '.shipment-header .shipment-shipping-address .save', function (event) {
		$('body').loading(loadingIndicatorSettings);
		var $addressFields = $(this).closest('.shipment-shipping-address-form').find('input, select');
		//if (shippingAddressFormValidator.checkForm()) {
			setOPCAddress(Urls.singleShipping, $addressFields, 'dwfrm_shippingaddress_saveopcaddress').then(function (data) {
				$(document).trigger('Cart.Update');
			});
		//}
	});

	$(document).off('click.CancelShippingAddress').on('click.CancelShippingAddress', '.shipment-header .shipment-shipping-address .cancel', function (event) {
		$(this).closest('.shipment-header').attr('status', 'default');
	});

	$(document).off('click.UpdateShippingMethod').on('click.UpdateShippingMethod', '.shipment-header .shipment-shipping-method .update', function (event) {
		var $shipmentHeader = $(this).closest('.shipment-header');
		if ($shipmentHeader.attr('status') != 'shippingmethodform') {
			$shipmentHeader.attr('status', 'shippingmethodform');
		} else {
			$shipmentHeader.attr('status', 'default');
		}
	});

	$(document).off('click.SelectShippingMethod').on('click.SelectShippingMethod', '.shipment-header .shipment-shipping-method .shipping-method-option', function (event) {
		selectShippingMethod($(this).closest('[shipment]').attr('shipment'), $(this).attr('shipping-method'))
	});
}

function selectShippingMethod(shipmentID, shippingMethodID) {
	$('body').loading(loadingIndicatorSettings);
    var url = getShippingMethodURL(null, Urls.selectShippingMethodsList, {shipmentID: shipmentID, shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
			$(document).trigger('Cart.Update');
        }
    });
}

function getShippingMethodURL($form, url, extraParams) {
    var params = {};
	if ($form) {
		params = $.extend(params, {
			isStore: $form.find('input[name$="_isStore"]').prop('checked'),
			myStoreID: $form.find('input[name$="_myStoreID"]').val(),
			address1: $form.find('input[name$="_address1"]').val(),
			address2: $form.find('input[name$="_address2"]').val(),
			address3: $form.find('input[name$="_address3"]').val(),
			countryCode: $form.find('select[id$="_country"]').val(),
			stateCode: $form.find('select[id$="_state"]').val(),
			postalCode: $form.find('input[name$="_postal"]').val(),
			city: $form.find('input[name$="_city"]').val()
		});
	}

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

function setOPCAddress(url, $form, name) {
	var sanitizedFormData = {};
	var serializedFormDataArray = $form.serializeArray();
	var hasCountryField = false;
	for (var serializedFormDataKey in serializedFormDataArray) {
		var serializedFormElement = serializedFormDataArray[serializedFormDataKey];
		if (serializedFormElement && serializedFormElement.name && sanitizedFormData[serializedFormElement.name]) {
			sanitizedFormData[serializedFormElement.name] += ',' + serializedFormElement.value;
		} else {
			sanitizedFormData[serializedFormElement.name] = serializedFormElement.value;
		}
		if (serializedFormElement && serializedFormElement.name && serializedFormElement.name.indexOf('_country') < 0) {
			hasCountryField = true;
		}
	}
	if (hasCountryField) {
		var $countryField = $form.find('[name$="_country"]');
		sanitizedFormData[$countryField.attr('name')] = $countryField.val();
	}
	sanitizedFormData[name] = name;

    return new Promise(function (resolve, reject) {
		$.ajax({
			type: 'POST',
			url: util.ajaxUrl(url),
			data: sanitizedFormData,
			success: function(data) {
				resolve(data);
			},
			error: function(data) {
				reject(data);
			}
		});
    });
};

exports.updateCart = updateCart;
exports.init = function (optionsParameter) {
	options = optionsParameter;
	if (options && options.isCartPage) {
    	isCartPage = true;
    }

    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
	if (options && options.StoreUIVersion == '2024Redesign') {
		initializeRedesignEvents();
		cartEcho.init(options);
		RewardsCredit.initialize($.extend({ LoadingIndicator: {
			IsLabelShown: false,
			BackgroundColor: 'transparent'
		}}, options));
		searchRewards();		
	}
    updateCart('dwfrm_cart_updateCart');
};
