'use strict';

var options = {};
var dataBuffer = {};

function initializeQRCode() {
	if ('QRCode' in window && 'PrivateCheckout' in options && options.PrivateCheckout.Enabled) {
		var qrcode = new QRCode(document.getElementById('qrcode'), {
			text: options.PrivateCheckout.Url,
			width: 256,
			height: 256,
			colorDark : "#000000",
			colorLight : "#ffffff",
			correctLevel : QRCode.CorrectLevel.H
		});

		dataBuffer['privateCheckoutStatusInterval'] = setInterval(function() {
			$.ajax({
				type: 'post',
				url: Urls.privateCheckoutStatus,
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify({
					ID: options.PrivateCheckout.BasketCustomObjectId
				}),
				success: function(data) {
					$('.qrcode').attr('status', data.Status);
					if (data.Status == 'Complete') {
						$('.qrcode').find('.text').text('Order # ' + data.Order);
						clearInterval(dataBuffer['privateCheckoutStatusInterval']);
					}
				}
			});
		}, 2000);
	}
}

exports.init = function (optionsParameter) {
	options = optionsParameter;

	if (options.StoreUIVersion == '2024Redesign') {
		initializeQRCode();
	}
};